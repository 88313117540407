import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import Header from './Header';
import DrawerMenu from './DrawerMenu';

const useStyles = makeStyles(() => ({
    stripe: {
        top: 0,
        left: 0,
        right: 0,
        height: 200,
        position: 'fixed',
        background: '#404040',
        pointerEvents: 'none',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    contentWrapper: {
        display: 'flex',
        flexGrow: 1,
        border: '0 solid #f7f7f7',
        borderRadius: '4px',
        flexDirection: 'column',
        backgroundColor: '#fff'
    }
}));

export default function DashboardLayout({ Icon, title, subtitle, children }) {
    const classes = useStyles();

    return (
        <Box sx={{ background: '#F4F5F7', display: 'flex', position: 'relative' }}>
            {/* <DrawerMenu /> */}

            <Box component="main"
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    height: { lg: '100vh', xs: 'calc(100vh - 64px)' },
                    mt: { lg: 0, xs: '64px' },
                    overflow: 'auto',
                    zIndex: 10
                }}
            >
                <Container maxWidth="lg" className={classes.container}>
                    <Header title={title} />

                    <Box className={classes.contentWrapper}>
                        <Container maxWidth="lg" className={classes.container}>
                            {children}
                        </Container>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}