import React from 'react';
import _ from 'lodash';
import { Box, Grid, Typography } from "@mui/material";

export default function NotAvailable() {
    return (
        <Grid
            container
            justifyContent={'center'}
        >
            <Grid item md={8} xs={12} paddingX={2} paddingY={4}>

                <Box display='flex' alignItems='center' justifyContent='center' marginY={2}>
                    <Typography align="center" variant="body1">
                        Funcionalidade não disponível.
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}