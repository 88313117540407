import React, { useState } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import _ from 'lodash';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Tour steps
const TOUR_STEPS = [
    {
        placement: 'center',
        target: 'body',
        content:
            <Grid container justifyContent='left'>
                <Typography component="h1" variant="h5" align='left'>
                    Seja bem-vindo(a) à Mobilizei!
                </Typography>
                <Typography sx={{ mt: 2, width: '100%' }} component="h2" variant="body1" align='left'>
                    O aplicativo da sua loja já está pronto!
                </Typography>
                <Typography sx={{ mt: 2 }} component="h2" variant="body1" align='left'>
                    Vamos te orientar rapidamente como você pode fazer para testá-lo.
                </Typography>
            </Grid>,
        disableBeacon: true
    },
    {
        target: ".onboardingPreviewerSmartphone",
        content:
            <Grid container justifyContent='center'>
                <Typography sx={{ mt: 2, width: '100%' }} component="h2" variant="body1" align='left'>
                    Configuramos o seu aplicativo com nosso <strong>tema WEB,</strong> que reproduz o site da sua loja.
                </Typography>
                <Typography sx={{ mt: 2 }} component="h2" variant="body1" align='left'>
                    Dentro do painel de controle você irá encontrar <strong>outros temas</strong> e poderá criar um <strong>layout exclusivo para seu app!</strong>
                </Typography>
            </Grid>,
        placement: 'left'
    },
    {
        target: ".onboardingPreviewerTabBarColors",
        content:
            <Grid container justifyContent='center'>
                <Typography sx={{ mt: 2, width: '100%' }} component="h2" variant="body1" align='left'>
                    Seu aplicativo conta com um poderoso <strong>editor de layout</strong>, com muitas opções de personalização.
                </Typography>
                <Typography sx={{ mt: 2, width: '100%' }} component="h2" variant="body1" align='left'>
                    Aqui você pode ver um exemplo de como são realizadas as alterações de cores do menu inferior.
                </Typography>
            </Grid>,
        placement: 'auto'
    },
    {
        target: ".onboardingPreviewerQrCode",
        content:
            <Grid container justifyContent='center'>
                <Typography sx={{ mt: 2, width: '100%' }} component="h2" variant="body1" align='left'>
                    Escaneando o <strong>QR Code</strong> você vai poder testar o app <strong>diretamente no seu celular.</strong>
                </Typography>
            </Grid>,
        placement: 'auto'
    },
    {
        target: ".onboardingPreviewerPanelButton",
        content:
            <Grid container justifyContent='center'>
                <Typography sx={{ mt: 2, width: '100%' }} component="h2" variant="body1" align='left'>
                    Quando você estiver pronto, confira o <strong>painel de controle</strong> e tenha acesso à <strong>configuração completa</strong> do seu aplicativo!
                </Typography>
            </Grid>,
        placement: 'bottom'
    }
];

// Tour component
const Tour = () => {
    const [run, setRun] = useState(true);

    const handleJoyrideCallback = data => {
        const { status } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setRun(false);
        }
    };

    return (
        <>
            <IconButton edge="end" onClick={() => setRun(true)}>
                <FontAwesomeIcon icon={['far', 'question-circle']} fixedWidth />
            </IconButton>

            <Joyride
                callback={handleJoyrideCallback}
                continuous={true}
                disableOverlayClose={true}
                locale={{
                    back: "voltar",
                    close: "Fechar",
                    last: "fechar",
                    next: "próximo",
                    skip: <CloseIcon />
                }}
                run={run}
                showSkipButton={true}
                steps={TOUR_STEPS}
                styles={{
                    buttonBack: {
                        color: '#37c961'
                    },
                    buttonClose: {
                        display: 'none',
                    },
                    buttonNext: {
                        backgroundColor: '#37c961',
                        fontFamily: 'Nunito, sans-serif',
                        fontSize: '16px'
                    },
                    options: {
                        zIndex: 9999,
                    }
                }}
            />
        </>
    );
};

export default Tour;