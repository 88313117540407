import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';

const LinkBehavior = React.forwardRef((props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#6660dc',
        },
        secondary: {
            main: '#000'
        },
        white: {
            main: '#fff'
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "#root": {
                    overflow: 'hidden',
                    position: 'relative'
                },
                a: {
                    color: '#6660dc',
                    cursor: 'pointer',
                    textDecoration: 'none'
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 2,
                    lineHeight: 1,
                    padding: '12px 30px'
                },
                containedPrimary: {
                    color: '#fff',
                    fontFamily: 'Nunito, sans-serif',
                    fontWeight: 400,
                    textTransform: 'capitalize'
                },
                textPrimary: {
                    textTransform: 'none'
                },
                textSecondary: {
                    textTransform: 'none'
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            },
            styleOverrides: {
                root: {
                    textDecoration: 'none'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    borderRadius: "4px",
                },
                outlined: {
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    color: "#6c757d",
                    fontSize: "16px",
                    lineHeight: 1.5,
                    padding: "10px"
                },
                select: {
                    color: '#6c757d',
                    fontFamily: 'Nunito, sans-serif!important',
                    fontSize: '14px!important'
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: "#323a46"
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#6c757d',
                    fontFamily: 'Nunito, sans-serif!important',
                    fontSize: '14px'
                },
                h2: {
                    fontWeight: 'bold'
                },
                caption: {
                    color: '#6c757d',
                    fontFamily: 'Nunito, sans-serif!important',
                    fontSize: '12px'
                }
            }
        }
    }
});

export default theme;