import { apiFetcher } from "./apiFetcher";

export const mobilizeiConvertrService = {
    validateConvertrToken
};

function validateConvertrToken(token) {
    return apiFetcher(
        `platforms/convertr/auth/token?token=${token}`, {
        method: "GET"
    }).then(response => response);
}